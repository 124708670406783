@use 'styles' as theme;

.root {
  --gradientDirection: to right;
  --gradientSize: #{theme.size(2)};
  --gradientColor: theme.$colorBlue100;

  @include theme.transition(opacity, slow);
  mask-image: linear-gradient(
    var(--gradientDirection),
    transparent 0%,
    var(--gradientColor) var(--gradientSize),
    var(--gradientColor) calc(100% - var(--gradientSize)),
    transparent 100%
  );

  overflow: hidden;
  opacity: 0;
  max-width: fit-content;
}

.ready {
  opacity: 1;
}

.vertical {
  --gradientDirection: to top;
}

.wrapper {
  @include theme.unstyledList;

  display: flex;

  .vertical & {
    flex-direction: column;
  }
}
