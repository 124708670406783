@use 'styles' as theme;
@import '../../styles/mixins';

.root {
  position: relative;
}

.aspectRatio {
  height: 0;
}

.imageWrapper {
  @include square(100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: no-repeat 50% 50%;
  background-size: cover;

  @media print {
    display: none;
  }
}

.printWrapper {
  display: none;

  @media print {
    display: block;
  }
}

.image {
  object-fit: cover;
}
