@use 'styles' as theme;

.rightContent {
  --offset: -#{theme.$framePaddingMobileUp};

  overflow: hidden;
  margin: 0 var(--offset);

  @include theme.mediaTabletLandscapeUp {
    --offset: -#{theme.$framePaddingTabletLandscapeUp};
  }

  @include theme.mediaDesktopUp {
    overflow: visible;
    align-items: flex-end;
  }
}
