@use 'styles' as theme;
.root {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: theme.$colorGrey200;
  }
}

.text {
  padding: theme.size(0 2);
  z-index: 1;
  text-transform: capitalize;
  background-color: theme.$colorWhite;
}
