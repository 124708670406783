@use 'styles' as theme;

.root {
  display: flex;
  gap: theme.size(1);
  flex-direction: column;
  justify-content: center;

  @include theme.mediaDesktopUp {
    flex-direction: row;
    margin: theme.size(-8) 0;
    gap: theme.size(2);
  }
}

.m {
  @include theme.mediaDesktopUp {
    height: theme.size(90);
  }
}

.rating {
  margin-top: theme.size(-1);
}

.illustrationWrapper {
  @include theme.square(theme.size(10));
}
