@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  display: flex;
  padding: 40px 0;
  background: theme.$colorGrey50;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
  margin: auto;
  padding: 45px 25px 50px;
  border-bottom: 3px solid theme.$colorYellow500;
  background: white;

  @include for-tablet-portrait-up {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.icon {
  width: 78px;
  height: 52px;
  margin-bottom: 10px;
}
