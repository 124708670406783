@use 'styles' as theme;

.root {
  padding: theme.size(3 0);
}

.title {
  @include theme.mediaTabletLandscapeUp {
    width: theme.size(25);
  }
}

.logos {
  --offset: #{theme.$framePaddingMobileUp};
  --offsetNegative: calc(var(--offset) * -1);
  --gradientOffset: #{theme.size(2)};

  @include theme.hideScrollbar;
  flex: 1;
  width: 100vw;
  margin: 0 var(--offsetNegative);
  padding: 0 var(--offset);
  scroll-padding: 0 var(--offset);
  overflow-x: auto;
  overflow-y: hidden;
  height: theme.size(10);
  scroll-snap-type: x mandatory;
  mask-image: linear-gradient(
    to left,
    transparent,
    theme.$colorBlack var(--gradientOffset),
    theme.$colorBlack calc(100% - var(--gradientOffset)),
    transparent
  );

  @include theme.mediaTabletLandscapeUp {
    --offset: #{theme.$framePaddingTabletLandscapeUp};
    --gradientOffset: #{theme.size(4)};

    margin-left: 0;
    padding-left: var(--gradientOffset);
  }

  @include theme.mediaDesktopUp {
    --offset: #{theme.$framePaddingDesktopUp};
  }
}

.logo {
  object-fit: contain;
  scroll-snap-align: center;
}

.wrapper {
  @include theme.mediaTabletLandscapeUp {
    flex-direction: row;
  }
}

.link {
  @include theme.outlineOnFocus;
  @include theme.transition(transform, $focusOutline: true);

  &:hover {
    transform: scale(1.05);
  }
}
