@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.frame {
  overflow: hidden;
  position: relative;
  border-radius: $borderRadius;
  max-height: 100%;

  &::after {
    @include positionFill;
    content: '';
    background: rgba(black, 0.3);
  }

  &.playing::after {
    display: none;
  }
}

.playButton {
  @include unstyledButton;
  @include absoluteCenter(53px);
  @include circle(53px);
  @include transition(transform);
  @include flexCentered;
  z-index: 1;
  background: rgba(#000, 0.5);
  border: 1px solid rgba(#fff, 0.5);

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }

  @include for-tablet-landscape-up {
    @include absoluteCenter(69px);
    @include circle(69px);
  }
}

.playIcon {
  @include square(50%);
  margin-right: -10px;
  color: theme.$colorWhite;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  > div {
    width: 100%;
    height: 100%;
  }
}

.videoPlaceholder {
  height: 0;
  padding-bottom: 56.25%;
  background: black;
}

.iframe {
  display: block;
}
