@use 'styles' as theme;

.articleCard {
  @include theme.mediaTabletPortraitOnly {
    grid-column: 3 / span 4;
  }

  @include theme.mediaTabletLandscapeUp {
    grid-column-start: var(--columnStart);
  }
}
