@use 'styles' as theme;

.pack {
  @include theme.boxShadow;

  --borderSize: 1px;
  --borderColor: #{theme.$colorGrey400};

  padding: theme.size(4);
  border: var(--borderSize) solid var(--borderColor);
  border-radius: theme.$borderRadiusL;
  background: theme.$colorWhite;
  text-align: center;
  position: relative;

  @include theme.mediaDesktopUp {
    text-align: left;
  }
}

.banner {
  --offset: calc(var(--borderSize) * -1);

  position: absolute;
  inset: var(--offset) var(--offset) auto;
  transform: translate(0, -100%);
  background-color: theme.$colorBlue;
  text-align: center;
  padding: theme.size(0.5);
  border-top-left-radius: theme.$borderRadiusL;
  border-top-right-radius: theme.$borderRadiusL;
}

.customOffer {
  @extend .pack;

  --borderColor: #{theme.$colorBlue};

  background: theme.$colorBlue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: theme.size(4);
}

.customOfferWrapper {
  container-type: inline-size;
  container-name: wrapper;

  @container wrapper (max-width: #{theme.size(75)}) {
    .customOffer {
      flex-direction: column;
      height: 100%;
    }

    .headingWrapper {
      flex-direction: column;
      justify-content: center;
    }

    .heading {
      text-align: center;
    }

    .button {
      width: 100%;
    }
  }
}

.headingWrapper {
  gap: theme.size(1.5);
  text-align: center;
  display: flex;
  align-items: center;
}

.heading {
  line-height: 1.25;
  text-align: left;
  text-wrap: balance;
}

.highlighted {
  --borderColor: #{theme.$colorBlue};
  --borderSize: 2px;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.actions {
  margin-top: auto;
}

.button {
  text-wrap: nowrap;
  text-align: center;
}

.previousPrice {
  text-decoration: line-through;
}
