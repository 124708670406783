@use 'styles' as theme;

.star {
  @include theme.square(var(--size));

  path {
    fill: var(--fillColor);
  }
}

.blue {
  --fillColor: #{theme.$colorBlue};
}

.yellow {
  --fillColor: #{theme.$colorYellow500};
}

.white {
  --fillColor: #{theme.$colorWhite};
}

.m {
  --size: #{theme.size(3)};
}

.s {
  --size: #{theme.size(2)};
}

.light {
  &.blue {
    --fillColor: #{theme.$colorBlue300};
  }

  &.yellow {
    --fillColor: #{theme.$colorYellow300};
  }
}
