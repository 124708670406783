@use 'styles' as theme;

.root {
  --columns: 1;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  gap: theme.size(2 4);

  &.vertical {
    gap: theme.size(3);
  }

  &.horizontal {
    @include theme.mediaTabletPortraitUp {
      --columns: 2;
    }
  }

  > * {
    grid-column: span 1;
  }
}

.fullWidth {
  @include theme.mediaTabletPortraitUp {
    grid-column: span 2;
  }
}
