@use 'styles' as theme;

.root {
  position: relative;
  isolation: isolate;
  overflow: hidden;
}

.backgroundImage {
  @include theme.absoluteCenter;
  color: transparent;
  z-index: -1;
  height: 100%;
  object-fit: contain;
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr;
  gap: theme.size(3 4);

  @include theme.mediaDesktopUp {
    grid-template-columns: repeat(12, 1fr);
  }
}

.card {
  @include theme.mediaDesktopUp {
    grid-column: span 4;
    grid-row: span 3;
    grid-template-rows: subgrid [person-start] [person-end quote-start] [quote-end review-start] [review-end];
  }
}

.cardVideo {
  height: 100%;
}
