@use 'styles' as theme;

.root {
  align-items: center;
}

.content {
  order: 1;

  @include theme.mediaTabletLandscapeUp {
    order: 0;

    &.reverse {
      order: 1;
    }
  }
}

.image {
  object-fit: contain;
}

.title {
  @include theme.headingS;

  @include theme.mediaTabletLandscapeUp {
    @include theme.headingL;
  }
}

.title,
.description {
  width: 100%;
  text-align: center;

  @include theme.mediaTabletLandscapeUp {
    text-align: left;
    width: unset;
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  @include theme.mediaTabletLandscapeUp {
    justify-content: start;
  }
}

.button {
  @include theme.mediaPhoneOnly {
    width: 100%;
  }
}

.imageWrapper {
  height: theme.size(40);
  position: relative;

  @include theme.mediaTabletPortraitUp {
    height: theme.size(50);
  }

  @include theme.mediaTabletLandscapeUp {
    height: theme.size(80);
  }
}

.button {
  --borderColor: #{theme.$colorWhite};
  color: theme.$colorWhite;
  box-shadow: inset 0 0 0 1px var(--borderColor);

  &:not(:disabled):hover {
    box-shadow: inset 0 0 0 1px var(--borderColor);
    background-color: theme.$colorWhite;
    color: theme.$colorBlue;
  }
}
