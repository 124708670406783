@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  width: 100%;
  max-width: theme.size(64);
  padding: 50px 0;
  color: theme.$colorGrey900;

  @include for-tablet-portrait-up {
    padding: 75px 0;
    margin: 50px auto;
    border: 1px solid theme.$colorGrey200;
    border-radius: $borderRadius;
  }
}

.container {
  margin: 0 auto;
  text-align: center;

  @include for-tablet-portrait-up {
    padding: 0 theme.size(10);
  }
}

.icon {
  @include square(50px);

  svg {
    @include square(70%);
  }
}

.loading {
  font-size: 20px;
}

.title {
  margin: 10px 0 0;
  font-size: 24px;
}

.content {
  margin-top: 5px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: theme.size(1);

  a {
    @include link;
    white-space: nowrap;
  }
}
