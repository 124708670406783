@use 'styles' as theme;
@import 'styles/variables.module';

.root {
  @include theme.boxShadow;

  display: grid;
  row-gap: theme.size(3);
  padding: theme.size(3);
  border: 1px solid theme.$colorGrey400;
  border-radius: theme.$borderRadiusXS;
  max-width: theme.size(60);
}

.avatarWrapper {
  @include theme.circle(theme.size(10));

  background-color: theme.$colorGrey400;
  position: relative;
}

.avatar {
  object-fit: cover;
  border-radius: 50%;
}

.youtubeButton {
  @include theme.unstyledButton;
  @include theme.outlineOnFocus($offset: theme.size(0.75));
  @include theme.transition(color, $focusOutline: true);

  width: fit-content;
  border-radius: theme.$borderRadiusXS;

  &:hover {
    color: $colorYoutube;
  }
}
