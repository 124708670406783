@use 'styles' as theme;

.formWrapper {
  --columns: 1;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  gap: theme.size(3 4);
  grid-template-areas:
    'fields'
    'message'
    'actions';

  &.horizontal {
    @include theme.mediaTabletLandscapeUp {
      --columns: 2;

      grid-template-areas:
        'fields message'
        'actions actions';
    }
  }
}

.actionsWrapper {
  display: grid;
  grid-area: actions;
}

.fieldsWrapper {
  grid-area: fields;

  .vertical & {
    @include theme.mediaTabletPortraitUp {
      max-width: theme.size(48);
    }
  }
}

.messageWrapper {
  grid-area: message;

  .vertical & {
    @include theme.mediaTabletPortraitUp {
      max-width: theme.size(48);
    }
  }

  > * {
    height: 100%;
  }
}
