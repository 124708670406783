@use 'styles' as theme;

.card {
  margin-right: theme.size(1);
  border-radius: theme.$borderRadiusXS;
  height: theme.size(25);
  width: theme.size(35);

  @include theme.mediaDesktopUp {
    height: unset;
    margin-right: 0;
    margin-bottom: theme.size(2);
  }
}

.imageWrapper {
  position: relative;
  overflow: hidden;

  @include theme.mediaDesktopUp {
    min-height: theme.size(27.5);
  }
}

.image {
  object-fit: cover;
}

.fact {
  --color: #{theme.$colorWhite};
  --bgColor: #{theme.$colorBlue};

  background-color: var(--bgColor);
  color: var(--color);
  padding: theme.size(5);
}

.white {
  --color: #{theme.$colorGrey900};
  --bgColor: #{theme.$colorWhite};
}

.black {
  --bgColor: #{theme.$colorBlack};
}

.divider {
  width: theme.size(12.5);
  height: 1px;
  background: var(--color);
}
