@use 'styles' as theme;
@import '../../../../styles/mixins.scss';

.root {
  background-color: theme.$colorBlue100;
}

.heading {
  text-align: center;

  @include theme.mediaTabletLandscapeUp {
    text-align: left;
  }
}

.supportBlock {
  order: -1;
  justify-self: center;

  @include theme.mediaTabletLandscapeUp {
    align-self: center;
    order: unset;
    justify-self: end;
  }
}
