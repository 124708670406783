@use 'styles' as theme;
@import '../../styles/variables.module';
@import '../../styles/mixins';

.root {
  display: flex;
  padding: 10px;
  border-radius: $borderRadiusSm;
  text-align: left;
  font-size: 14px;
}

.inline {
  display: inline-flex;
}

.success {
  background: lighten(theme.$colorGreen, 55%);
  color: theme.$colorGreen;
}

.error {
  background: lighten(theme.$colorRed, 31%);
  color: theme.$colorRed;
}

.warning {
  background: lighten(theme.$colorYellow500, 42%);
  color: theme.$colorYellow500;
}

.icon {
  @include square(20px);
  flex-shrink: 0;
  margin-right: 8px;

  .success & {
    color: theme.$colorGreen;
  }

  .error & {
    color: theme.$colorRed;
  }

  .warning & {
    color: theme.$colorYellow500;
  }
}

.text {
  flex: 1;
}
